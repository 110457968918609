import { gql } from '@apollo/client'

export const GET_LAST_ROTATE_INFO = gql`
  query getLastRotateInfo($clientId: ID!) {
    getLastRotateInfo(clientId: $clientId) {
      createdAt
      performedByEmail
    }
  }
`
