import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { Dispatch, SetStateAction } from 'react'
import { Size } from '@toasttab/buffet-pui-text-base'
import { SearchParams } from '../types/search-params.type'

export interface ConnectedRestaurantsSearchProps {
  testId?: string | number
  searchParams: SearchParams
  setSearchParams: Dispatch<SetStateAction<SearchParams>>
}

export const ConnectedRestaurantsSearch = ({
  testId = 'search-connected-restaurants',
  searchParams,
  setSearchParams
}: ConnectedRestaurantsSearchProps) => {
  return (
    <div data-testid={testId} className='ml-8 m-2 w-72'>
      <SearchInput
        testId='connected-restaurants-search-field'
        placeholder='Search connected restaurants'
        value={searchParams.term}
        onChange={(e) => setSearchParams({ term: e.target.value, page: 1 })}
        size={Size.sm}
        autoComplete='off'
      />
    </div>
  )
}
