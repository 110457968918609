import { useMemo } from 'react'
import { Column } from 'react-table'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

const truncateCellValueWithTooltip = ({ cell }: any) =>
  cell.value ? (
    <Tooltip content={() => cell.value} placement='bottom'>
      <div className='truncate'>{cell.value}</div>
    </Tooltip>
  ) : (
    <div>-</div>
  )

export const useTableColumns = () => {
  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Restaurant Name',
        accessor: 'restaurantName',
        width: 320,
        minWidth: 320,
        maxWidth: 340,
        disableSortBy: true,
        Cell: ({
          cell: {
            value: { restaurantName, restaurantLocationName }
          }
        }: any) => (
          <div className='h-12'>
            <Tooltip content={() => restaurantName} placement='bottom'>
              <div className='truncate'>{restaurantName}</div>
            </Tooltip>
            <Tooltip content={() => restaurantLocationName} placement='bottom'>
              <div className='type-subhead text-gray-100'>
                {restaurantLocationName}
              </div>
            </Tooltip>
          </div>
        )
      },
      {
        Header: 'Connection Date',
        accessor: 'createdDate',
        width: 80,
        Cell: truncateCellValueWithTooltip
      },
      {
        Header: 'Added By',
        accessor: 'userEmail',
        disableSortBy: true,
        Cell: truncateCellValueWithTooltip
      },
      {
        Header: 'Restaurant GUID',
        accessor: 'restaurantGuid',
        Cell: truncateCellValueWithTooltip
      },
      {
        Header: 'Location ID',
        accessor: 'externalRestaurantRef',
        disableSortBy: true,
        Cell: truncateCellValueWithTooltip
      },
      {
        Header: 'Group ID',
        accessor: 'externalGroupRef',
        disableSortBy: true,
        Cell: truncateCellValueWithTooltip
      }
    ],
    []
  )

  return { columns }
}
