import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { withScope, captureException } from '@sentry/browser'
import { ConnectedRestaurants } from '../../types/connected-restaurants.type'
import { GET_CONNECTED_RESTAURANTS_FOR_PARTNER } from '../queries/getConnectedRestaurants'
import { SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER } from '../queries/searchConnectedRestaurants'
import { TableParams } from '../../types'
import { SearchParams } from '../../types/search-params.type'

export interface UseConnectedRestaurantsProps extends TableParams {
  partnerGuid: string
  searchParams?: SearchParams
}

export const useConnectedRestaurants = ({
  partnerGuid,
  searchParams,
  ...rest
}: UseConnectedRestaurantsProps) => {
  const [connectedRestaurants, setConnectedRestaurants] =
    useState<ConnectedRestaurants>()

  const {
    data,
    loading: connectedRestaurantsLoading,
    error: connectedRestaurantsError
  } = useQuery(GET_CONNECTED_RESTAURANTS_FOR_PARTNER, {
    skip: !!searchParams?.term,
    context: { headers: { 'toast-organization-guid': partnerGuid } },
    variables: {
      ...rest
    },
    onError: (apolloError) => {
      withScope((scope) => {
        scope.setTag('toast_component', 'useConnectedRestaurants')
        scope.setTag(
          'toast_function',
          'useQuery:GET_CONNECTED_RESTAURANTS_FOR_PARTNER'
        )
        scope.setLevel('warning')
        captureException(apolloError)
      })
    }
  })

  const { data: searchData, loading: searchConnectedRestaurantsLoading } =
    useQuery(SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER, {
      skip: !searchParams?.term,
      context: { headers: { 'toast-organization-guid': partnerGuid } },
      variables: {
        term: searchParams?.term,
        page: searchParams?.page
      },
      onError: (apolloError) => {
        withScope((scope) => {
          scope.setTag('toast_component', 'useConnectedRestaurants')
          scope.setTag(
            'toast_function',
            'useQuery:SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER'
          )
          scope.setLevel('warning')
          captureException(apolloError)
        })
      }
    })

  useMemo(() => {
    if (searchData && searchData.hasOwnProperty('searchConnectedRestaurants')) {
      setConnectedRestaurants(searchData.searchConnectedRestaurants)
    } else if (data && data.hasOwnProperty('getConnectedRestaurants')) {
      setConnectedRestaurants(data.getConnectedRestaurants)
    }
  }, [data, searchData])

  return {
    connectedRestaurantsLoading,
    searchConnectedRestaurantsLoading,
    connectedRestaurantsError,
    connectedRestaurants
  }
}
