import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { PageAside, PageInfoList } from '@toasttab/buffet-pui-wizard-templates'

export interface CustomPageAsideProps {
  testId?: string
  topContent?: ReactNode
}

const MARKETPLACE_LISTING_HELP_ITEMS = [
  {
    Icon: QuestionAnswerDisplayIcon,
    header: 'Toast Developer Relations are here to help!',
    description: (
      <Button
        testId={'support-link'}
        as={Link}
        to='https://developers.toasttab.com/hc/en-us/community/topics'
        variant='text-link'
        target='_blank'
      >
        Open a support ticket
      </Button>
    )
  }
]

/**
 * This is a custom PageAside component
 * where the content at the bottom is a CTA to get support from Dev-Rel team
 * while the top is customisable, depending on the Marketplace Listing edit section
 */
export function CustomPageAside({ testId, topContent }: CustomPageAsideProps) {
  return (
    <PageAside testId={testId} className='items-center justify-between'>
      <div data-testid='top-content'>{topContent}</div>
      <div data-testid='bottom-content'>
        <PageInfoList items={MARKETPLACE_LISTING_HELP_ITEMS} />
      </div>
    </PageAside>
  )
}
