import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { Chip, Chips } from '@toasttab/buffet-pui-chips'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { EmailDisplayIcon, InfoDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Label } from '@toasttab/buffet-pui-text-base'
import {
  ContentFooter,
  ContentHeader,
  ContinueButton,
  NavigationContainer,
  PageBody,
  PageInfoList,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { addOptionPlaceholder } from '../../utils/addOptionPlaceholder'
import { BackButtonWithWarning } from '../BackButtonWithWarning/BackButtonWithWarning'
import { CustomPageAside } from '../CustomPageAside/CustomPageAside'
import { OpenSupportTicketHelperText } from '../EditListingPageBody/OpenSupportTicketHelperText'

export function AboutYourAppInfoForm({
  partnerTileInfo,
  hasChanges,
  handleContinue,
  setCurrentStepHasError
}: {
  partnerTileInfo: PartnerTileInfo
  hasChanges: (currentValues: PartnerTileInfo) => boolean
  handleContinue: (value: Partial<PartnerTileInfo>) => void
  setCurrentStepHasError: Dispatch<SetStateAction<boolean>>
}) {
  const initialValues = useMemo(
    () => ({
      onboardingEmail: partnerTileInfo.onboardingEmail,
      categories: addOptionPlaceholder(
        partnerTileInfo.categories?.map((category) => category.guid)
      ),
      countries: addOptionPlaceholder(partnerTileInfo.countries)
    }),
    [
      partnerTileInfo.categories,
      partnerTileInfo.countries,
      partnerTileInfo.onboardingEmail
    ]
  )
  const form = useFormik<
    Pick<PartnerTileInfo, 'onboardingEmail' | 'countries'> & {
      categories: string[]
    }
  >({
    initialValues,
    onSubmit: (value) => {
      handleContinue({ onboardingEmail: value.onboardingEmail })
    },
    initialTouched: { onboardingEmail: true },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      onboardingEmail: Yup.string()
        .required('A email is required.')
        .email('Invalid email.')
    })
  })

  useEffect(() => {
    setCurrentStepHasError(!!Object.keys(form.errors).length)
  }, [form.errors, setCurrentStepHasError])

  const categoryOptions = useMemo(
    () =>
      addOptionPlaceholder(
        partnerTileInfo.categories?.map((category) => ({
          label: category.label,
          value: category.guid
        }))
      ),
    [partnerTileInfo.categories]
  )

  const countryOptions = useMemo(
    () => addOptionPlaceholder(partnerTileInfo.countries),
    [partnerTileInfo.countries]
  )

  return (
    <PageBody>
      <PageMain>
        <ContentHeader title='About your app' contentDivider />
        <FormikProvider value={form}>
          <Form className='gap-4 flex flex-col'>
            <TextInputField
              name='onboardingEmail'
              label='Onboarding email address'
              testId='onboardingEmail-field'
              required
              helperText='Email that Toast will send integration set-up requests to (including customer contact info and restaurant GUID)'
            />
            <div data-testid='categories-section'>
              <Label>Categories</Label>
              <Chips>
                {categoryOptions.map((item) => {
                  const chip =
                    item === 'N/A' ? { label: 'N/A', value: 'N/A' } : item
                  const { label, value } = chip
                  return (
                    <Chip
                      key={value}
                      label={label}
                      disabled
                      onClick={() => false}
                      size='xs'
                    />
                  )
                })}
              </Chips>
              <OpenSupportTicketHelperText fieldDescription='categories' />
            </div>

            <div data-testid='countries-section'>
              <Label>Available in</Label>
              <Chips>
                {countryOptions.map((item) => {
                  return (
                    <Chip
                      key={item}
                      label={item}
                      disabled
                      onClick={() => false}
                      size='xs'
                    />
                  )
                })}
              </Chips>
              <OpenSupportTicketHelperText fieldDescription='country availability' />
            </div>

            <ContentFooter>
              <NavigationContainer>
                <BackButtonWithWarning
                  unsavedChanges={hasChanges({
                    ...partnerTileInfo,
                    onboardingEmail: form.values.onboardingEmail
                  })}
                  navigateTo='/marketplace-listing'
                />
                <ContinueButton
                  as='button'
                  disabled={!form.isValid || form.isSubmitting}
                  testId='submit-about-your-app-button'
                  type='submit'
                >
                  Continue
                </ContinueButton>
              </NavigationContainer>
            </ContentFooter>
          </Form>
        </FormikProvider>
      </PageMain>
      <CustomPageAside
        testId='listing-page-aside'
        topContent={
          <PageInfoList
            items={[
              {
                Icon: EmailDisplayIcon,
                header:
                  'Want to learn more about what happens when a restaurant adds your integration in Toast Partner Integrations?',
                description: (
                  <a
                    href='https://doc.toasttab.com/doc/devguide/apiPartnersGettingAccessibleRestaurants.html'
                    target='_blank'
                    className='inline-link'
                    rel='noreferrer'
                  >
                    Learn more here
                  </a>
                )
              },
              {
                Icon: InfoDisplayIcon,
                header:
                  'A webhook event can also be setup to notify you when a new restaurant adds your integration.',
                description: (
                  <a
                    href='https://doc.toasttab.com/doc/devguide/apiPartnersWebhook.html'
                    target='_blank'
                    className='inline-link'
                    rel='noreferrer'
                  >
                    Learn more here
                  </a>
                )
              }
            ]}
          />
        }
      />
    </PageBody>
  )
}
