import { StepState } from '../../types/stepState.type'

export const MarketplaceListingSteps = [
  'About your app',
  'Support',
  'Marketplace listing',
  'Submit'
] as const
export type MarketplaceListingStep = (typeof MarketplaceListingSteps)[number]

export function getStepState(
  currIdx: number,
  stepIdx: number,
  currError: boolean,
  currentStep: MarketplaceListingStep
): StepState {
  if (currIdx < stepIdx) return 'inactive'
  if (currIdx === stepIdx && currError) return 'error'
  if (currIdx === stepIdx && currentStep === 'Submit') return 'complete'
  if (currIdx === stepIdx) return 'active'
  return 'complete'
}
