import { ReactNode } from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button } from '@toasttab/buffet-pui-buttons'
import { convertToDashCase } from '../../utils'

export interface HomepageCardProps {
  title: string
  description: string
  actionTitle: string
  actionLink: string
  imageComponent: ReactNode
  className?: string
}

export const HomepageCard = ({
  title,
  description,
  actionTitle,
  actionLink,
  imageComponent,
  className = ''
}: HomepageCardProps) => {
  return (
    <CardContainer
      testId={`home-card-${convertToDashCase(title)}`}
      data-toast-track-id='tpc-depot-main-home-card'
      className={`flex flex-col justify-between ${className}`}
    >
      <div className='lg:h-52'>
        <div className='type-headline-4 pb-6'>{title}</div>
        <p className='type-default text-secondary lg:min-h-min'>
          {description}
        </p>
      </div>
      <Button
        as='a'
        variant='secondary'
        href={actionLink}
        size='sm'
        className='w-min mt-2'
        target='_blank'
        data-toast-track-id={`tpc-depot-main-home-card-${convertToDashCase(
          actionTitle
        )}`}
      >
        {actionTitle}
      </Button>
      <div
        data-testid='card-img'
        className='w-full mt-8 lg:h-40 flex flex-col justify-end'
      >
        {imageComponent}
      </div>
    </CardContainer>
  )
}
