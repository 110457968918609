import {
  ClientSecret,
  RotateSecretModal
} from '@toasttab/buffet-patterns-rotate-secret-modal'
import { ViewSecretModal } from '@toasttab/buffet-patterns-view-secret-modal'
import { useCallback, useMemo } from 'react'
import { useClientSecretContext } from '../../contexts'

const ERROR_MESSAGE =
  'Toast encountered a problem. Your secret has not been rotated, please try again.'

export const RotateSecretContainer = () => {
  const {
    rotationInfo,
    isRotateModalOpen,
    setIsRotateModalOpen,
    hasConfirmedRotation,
    setHasConfirmedRotation,
    rotatingClientSecret,
    clientSecret,
    onConfirmRotateSecret,
    rotateClientSecretError
  } = useClientSecretContext()

  const onSecretViewed = useCallback(() => {
    setHasConfirmedRotation(false)
    setIsRotateModalOpen(false)
  }, [setHasConfirmedRotation, setIsRotateModalOpen])

  const clientSecretInfo: ClientSecret = useMemo(
    () => ({
      type: 'Toast API Access',
      createdOnDate: rotationInfo?.createdAt
    }),
    [rotationInfo?.createdAt]
  )

  return (
    <>
      {!hasConfirmedRotation ? (
        <RotateSecretModal
          onClose={() => setIsRotateModalOpen(false)}
          isOpen={isRotateModalOpen}
          onRotate={onConfirmRotateSecret}
          clientSecret={clientSecretInfo}
          loading={rotatingClientSecret}
          errorMsg={rotateClientSecretError ? ERROR_MESSAGE : undefined}
        />
      ) : (
        <ViewSecretModal
          clientSecret={{
            secret: clientSecret?.clientSecret!,
            createdOnDate: rotationInfo?.createdAt
          }}
          isOpen={isRotateModalOpen}
          showSecretInitially={true}
          onClose={onSecretViewed}
        />
      )}
    </>
  )
}
