import { Dispatch, SetStateAction, useState } from 'react'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { AboutYourAppInfoForm } from '../AboutYourAppInfoForm/AboutYourAppInfoForm'
import { MarketplaceListingInfoForm } from '../MarketplaceListingInfoForm/MarketplaceListingInfoForm'
import { MarketplaceListingStep } from './stepperUtilities'
import { SubmittedTileStepBody } from './SubmittedTileStepBody'
import { SupportInfoForm } from '../SupportInfoForm/SupportInfoForm'

export function RenderCurrentStepBody({
  currentStep,
  partnerTileInfo,
  setCurrentStepIdx,
  setCurrentStepHasError
}: {
  currentStep: MarketplaceListingStep
  partnerTileInfo: PartnerTileInfo
  setCurrentStepIdx: Dispatch<SetStateAction<number>>
  setCurrentStepHasError: Dispatch<SetStateAction<boolean>>
}) {
  const [editPartnerTileInfo, setEditPartTileInfo] = useState({
    ...partnerTileInfo
  })
  const hasChanges = (currentValues: PartnerTileInfo): boolean => {
    return JSON.stringify(partnerTileInfo) !== JSON.stringify(currentValues)
  }

  if (currentStep === 'About your app')
    return (
      <AboutYourAppInfoForm
        handleContinue={(value) => {
          setEditPartTileInfo((existingInfo) => ({ ...existingInfo, ...value }))
          setCurrentStepIdx((stepIdx) => stepIdx + 1)
        }}
        hasChanges={hasChanges}
        partnerTileInfo={editPartnerTileInfo}
        setCurrentStepHasError={setCurrentStepHasError}
      />
    )
  if (currentStep === 'Support')
    return (
      <SupportInfoForm
        handleGoBack={(value) => {
          setEditPartTileInfo((existingInfo) => ({ ...existingInfo, ...value }))
          setCurrentStepIdx((stepIdx) => stepIdx - 1)
        }}
        handleContinue={(value) => {
          setEditPartTileInfo((existingInfo) => ({ ...existingInfo, ...value }))
          setCurrentStepIdx((stepIdx) => stepIdx + 1)
        }}
        partnerTileInfo={editPartnerTileInfo}
        setCurrentStepHasError={setCurrentStepHasError}
      />
    )
  if (currentStep === 'Marketplace listing')
    return (
      <MarketplaceListingInfoForm
        handleGoBack={(value) => {
          setEditPartTileInfo((existingInfo) => ({ ...existingInfo, ...value }))
          setCurrentStepIdx((stepIdx) => stepIdx - 1)
        }}
        handleContinue={(value) => {
          setEditPartTileInfo((existingInfo) => ({ ...existingInfo, ...value }))
          setCurrentStepIdx((stepIdx) => stepIdx + 1)
        }}
        hasChanges={hasChanges}
        partnerTileInfo={editPartnerTileInfo}
        setCurrentStepHasError={setCurrentStepHasError}
      />
    )

  if (currentStep === 'Submit')
    return <SubmittedTileStepBody editPartnerTileInfo={editPartnerTileInfo} />

  return null
}
