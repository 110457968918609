import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek
} from '@toasttab/buffet-pui-date-utilities'

const DateRanges = [
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'This month',
  'Last month'
] as const
type DateRange = (typeof DateRanges)[number]

export function getDefinedRanges(): {
  [dateRange in DateRange]: { from: Date; to: Date }
} {
  const now = new Date()

  return {
    Today: {
      from: startOfDay(now),
      to: endOfDay(now)
    },
    Yesterday: {
      from: startOfDay(addDays(now, -1)),
      to: endOfDay(addDays(now, -1))
    },
    'This week': {
      from: startOfWeek(now),
      to: endOfWeek(now)
    },
    'Last week': {
      from: startOfWeek(addDays(now, -7)),
      to: endOfWeek(addDays(now, -7))
    },
    'This month': {
      from: startOfMonth(now),
      to: endOfMonth(now)
    },
    'Last month': {
      from: startOfMonth(addMonths(now, -1)),
      to: endOfMonth(addMonths(now, -1))
    }
  }
}
