import { getFormattedDate } from './getFormattedColumns'
import { ActivityLog } from '../../../types'
import { ActionsMapping } from '../../../constants'

export function getFormattedText(column: keyof ActivityLog, value: unknown) {
  if (column === 'timestamp')
    return getFormattedDate(value as ActivityLog['timestamp'])
  if (column === 'action') return ActionsMapping[value as ActivityLog['action']]
  return value as string
}
