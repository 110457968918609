import { useMutation } from '@apollo/client'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { UploadImage } from '../../types/uploadImage.type'
import { UPLOAD_IMAGE } from '../mutations/uploadImage'
import { useSentry } from 'banquet-runtime-modules'

export function useUploadImage() {
  const { showErrorSnackBar } = useSnackBar()
  const { partner } = useDepotBanquetProps()

  const { hub } = useSentry()

  const [uploadImageMutation] = useMutation<{
    uploadImage: UploadImage
  }>(UPLOAD_IMAGE, {
    context: { headers: { 'toast-organization-guid': partner?.guid } },
    onError: (error) => {
      hub.captureException(error)
      showErrorSnackBar(
        'Oops! There has been an error uploading the image. Please try again.',
        {
          testId: 'upload-image-error-snackbar'
        }
      )
    }
  })

  return {
    uploadImageMutation
  }
}
