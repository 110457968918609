import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'

export interface ScopesSectionProps {
  testId?: string | number
  scopes: string[]
}

const ScopeDescriptionsButton = () => (
  <Button
    as='a'
    variant={Button.Variant?.['text-link']}
    target='_blank'
    href='https://doc.toasttab.com/doc/devguide/apiScopes.html'
    className='mt-4'
    data-toast-track-id='tpc-depot-main-credentials-scope-descriptions'
  >
    Scope Descriptions
  </Button>
)

export const ScopesSection = ({
  testId = `scopes-section`,
  scopes
}: ScopesSectionProps) => (
  <div data-testid={testId}>
    {scopes.length > 0 ? (
      <>
        <Table testId='scopes-table' variant='plain'>
          <Head>
            <Row>
              <HeadingCell>Scope</HeadingCell>
            </Row>
          </Head>
          <Body testId='scopes'>
            {scopes.map((scope) => (
              <Row key={scope} testId='scope-row'>
                <Cell>{scope}</Cell>
              </Row>
            ))}
          </Body>
        </Table>
        <div className='flex flex-row justify-end'>
          <ScopeDescriptionsButton />
        </div>
      </>
    ) : (
      <div className='mt-8 mb-5'>
        <EmptyState
          button={
            <Button
              as='a'
              size='sm'
              href='https://developers.toasttab.com/hc/en-us/requests/new'
              target='_blank'
              variant={Button.Variant?.secondary}
            >
              Contact Developer Support
            </Button>
          }
          icon={<WarningOutlineIcon accessibility='decorative' />}
          testId='empty-scopes'
          title='Oops! No scopes were found for your connected application.'
        >
          <p>The Developer Support Team can assist you with API access.</p>
          <ScopeDescriptionsButton />
        </EmptyState>
      </div>
    )}
  </div>
)
