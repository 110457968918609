import { gql } from '@apollo/client'

export const GET_CONNECTED_RESTAURANTS_FOR_PARTNER = gql`
  query getConnectedRestaurants(
    $page: Int
    $createdFrom: String
    $createdTo: String
    $sortField: String
    $sortDirection: String
  ) {
    getConnectedRestaurants(
      page: $page
      createdFrom: $createdFrom
      createdTo: $createdTo
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      lastPageNum
      pageSize
      totalResultCount
      results {
        restaurantGuid
        restaurantName
        restaurantLocationName
        externalRestaurantRef
        externalGroupRef
        userEmail
        createdDate
      }
    }
  }
`
