import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { Pagination, usePagination } from '@toasttab/buffet-pui-pagination'
import { Table } from '@toasttab/buffet-pui-table'

import { ActivityLogs } from '../../types'
import { TableHeaders } from './TableHeaders'
import { TableRows } from './TableRows'
import { activityLogColumns } from './utils/getTableColumns'

export interface ActivityLogTableProps {
  activityLogs: ActivityLogs
}

export const ActivityLogTable = ({ activityLogs }: ActivityLogTableProps) => {
  const table = useReactTable({
    columns: activityLogColumns,
    data: activityLogs,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageSize: 20
      },
      sorting: [
        {
          id: 'timestamp',
          desc: true
        }
      ]
    }
  })

  const paginationProps = usePagination({
    currentPage: table.getState().pagination.pageIndex,
    totalPageCount: table.getPageCount(),
    visiblePages: 10,
    onChangePage(newPage) {
      table.setPageIndex(newPage)
    }
  })

  return (
    <>
      <Panel>
        <Table
          className='table table-striped table-bordered table-fixed'
          data-testid='activity-log-table'
        >
          <TableHeaders table={table} />
          <TableRows table={table} testId='activity-log-table-row' />
        </Table>
      </Panel>
      <div className='flex my-4 flex-row-reverse'>
        <Pagination {...paginationProps} />
      </div>
    </>
  )
}
