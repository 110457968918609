export declare const Locales: readonly [
  'en-CA',
  'en-GB',
  'en-US',
  'es-US',
  'en-IE'
]
export declare type Locale = (typeof Locales)[number]

const getLocales = () => 'en-US' as Locale

export default getLocales
