import { track } from '@toasttab/use-heap'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TPC_DEPOT_PAGE_UNLOAD_EVENT = 'TPC_DEPOT_PAGE_UNLOAD'

/**
 * track when page closed,
 * this hook needs to be placed inside `<Router>`
 */
export default function useTrackPageUnload() {
  // track when user close window
  useEffect(() => {
    function trackPageUnload() {
      track(TPC_DEPOT_PAGE_UNLOAD_EVENT)
    }
    window.addEventListener('beforeunload', trackPageUnload)

    return () => window.removeEventListener('beforeunload', trackPageUnload)
  }, [])
  const { pathname } = useLocation()

  // track when user changed location
  useEffect(() => {
    track(TPC_DEPOT_PAGE_UNLOAD_EVENT)
  }, [pathname])
}
