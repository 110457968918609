import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { useQuery } from '@apollo/client'
import { Layout } from '@local/layout'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { NotInterestedIcon, AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { GET_ACTIVITY_LOG } from '../../graphql/getActivityLog'
import { ActivityLogTableWithFilters } from '../ActivityLogTableWithFilters/ActivityLogTableWithFilters'

export const ActivityLogPage = () => {
  const { partner } = useDepotBanquetProps()

  return (
    <Layout title='Activity Log' subTitle={partner.name}>
      <ActivityLogPageBody />
    </Layout>
  )
}

export const ActivityLogPageBody = () => {
  const { partner } = useDepotBanquetProps()

  const { data, loading, error } = useQuery(GET_ACTIVITY_LOG, {
    context: { headers: { 'toast-organization-guid': partner.guid } }
  })

  if (error) return <ErrorPage500 />

  if (loading) {
    return (
      <div className='flex flex-col items-center gap-y-2 mt-20'>
        <MerryGoRound size='sm' />
        <div>Loading...</div>
      </div>
    )
  }

  return (
    <>
      <Alert variant='info' className='mb-4 w-full'>
        This page tracks actions in the Developer Portal only. Additional
        actions may have been taken by users in Toast Web.
      </Alert>
      {data?.getActivityLog && data.getActivityLog?.length ? (
        <ActivityLogTableWithFilters activityLogs={data.getActivityLog} />
      ) : (
        <Panel>
          <EmptyState
            title='No activity...yet'
            className='my-10'
            icon={<NotInterestedIcon accessibility='decorative' />}
            button={
              <Button
                className='w-80'
                variant='primary'
                iconLeft={<AutorenewIcon accessibility='decorative' />}
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            }
          >
            When someone takes an action in Developer Portal it will appear here
          </EmptyState>
        </Panel>
      )}
    </>
  )
}
