export enum SortField {
  restaurantName = 'RESTAURANT_NAME',
  restaurantGuid = 'RESTAURANT_GUID',
  createdDate = 'CONNECTION_DATE'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
