import { ApolloError, useQuery } from '@apollo/client'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react'
import { GET_LAST_ROTATE_INFO } from '../graphql'
import { ClientSecret, RotationInfo, RotationInfoData } from '../types'
import { useRotateSecret } from '../hooks/useRotateSecret'
import { formatClientSecretCreatedAt } from '../helpers'

export type ClientSecretContextValue = {
  rotationInfo: RotationInfo | undefined
  isRotateModalOpen: boolean
  setIsRotateModalOpen: Dispatch<SetStateAction<boolean>>
  hasConfirmedRotation: boolean
  setHasConfirmedRotation: Dispatch<SetStateAction<boolean>>
  rotatingClientSecret: boolean
  clientSecret: ClientSecret | undefined
  onConfirmRotateSecret: () => void
  rotateClientSecretError: ApolloError | undefined
}

/**
 * export for testing
 * use ClientSecretProvider and useClientSecretContext in components
 */
export const ClientSecretContext = createContext<ClientSecretContextValue>(
  {} as ClientSecretContextValue
)

export function ClientSecretProvider({
  children,
  clientId
}: PropsWithChildren<{ clientId: string }>) {
  const { partner } = useDepotBanquetProps()

  const [isRotateModalOpen, setIsRotateModalOpen] = useState(false)
  const [hasConfirmedRotation, setHasConfirmedRotation] = useState(false)

  const { data: rotationInfoData } = useQuery<{
    getLastRotateInfo: RotationInfoData
  }>(GET_LAST_ROTATE_INFO, {
    context: { headers: { 'toast-organization-guid': partner.guid } },
    variables: { clientId }
  })

  const rotationInfo = useMemo(() => {
    const rotationInfoCreatedAtTimestamp =
      rotationInfoData?.getLastRotateInfo?.createdAt
    if (rotationInfoData?.getLastRotateInfo && rotationInfoCreatedAtTimestamp) {
      return {
        ...rotationInfoData.getLastRotateInfo,
        createdAt: formatClientSecretCreatedAt(rotationInfoCreatedAtTimestamp)
      }
    }
  }, [rotationInfoData?.getLastRotateInfo])

  const {
    loading: rotatingClientSecret,
    clientSecret,
    onConfirmRotateSecret,
    rotateClientSecretError
  } = useRotateSecret(clientId, setHasConfirmedRotation)

  return (
    <ClientSecretContext.Provider
      value={{
        rotationInfo,
        isRotateModalOpen,
        setIsRotateModalOpen,
        hasConfirmedRotation,
        setHasConfirmedRotation,
        rotatingClientSecret,
        clientSecret,
        onConfirmRotateSecret,
        rotateClientSecretError
      }}
    >
      {children}
    </ClientSecretContext.Provider>
  )
}

export function useClientSecretContext() {
  return useContext(ClientSecretContext)
}
