import { useEffect, useState } from 'react'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { useDebounce } from 'react-use'

import { ConnectedRestaurantsTable } from '../ConnectedRestaurantsTable'
import { useConnectedRestaurants } from '../api/hooks/useConnectedRestaurants'
import { SortDirection, SortField, TableParams, SearchParams } from '../types'

export interface ConnectedRestaurantsProps {
  testId?: string | number
  partnerGuid: string
}

export const initialTableParams = {
  page: 1,
  sortField: SortField.createdDate,
  sortDirection: SortDirection.DESC
}

export const initialSearchParams = {
  term: '',
  page: 1
}

export const ConnectedRestaurants = ({
  testId = `connected-restaurants`,
  partnerGuid
}: ConnectedRestaurantsProps) => {
  const [tableParams, setTableParams] =
    useState<TableParams>(initialTableParams)

  const [searchParams, setSearchParams] =
    useState<SearchParams>(initialSearchParams)

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(
    initialSearchParams.term
  )

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchParams.term)
    },
    500,
    [setDebouncedSearchTerm, searchParams.term]
  )

  useEffect(() => {
    // after search, we should redirect the user to the first page
    setTableParams((previousState) => ({
      ...previousState,
      page: 1
    }))
  }, [searchParams, setTableParams])

  const {
    connectedRestaurants,
    connectedRestaurantsLoading,
    searchConnectedRestaurantsLoading,
    connectedRestaurantsError
  } = useConnectedRestaurants({
    partnerGuid,
    searchParams: { ...searchParams, term: debouncedSearchTerm },
    ...tableParams
  })

  if (connectedRestaurantsError) {
    return <ErrorPage500 />
  }

  return (
    <div data-testid={testId}>
      {connectedRestaurantsLoading && !connectedRestaurants && (
        <Skeleton testId='skeleton-loader' className='h-screen w-full' />
      )}
      {connectedRestaurants && (
        <ConnectedRestaurantsTable
          connectedRestaurants={connectedRestaurants}
          connectedRestaurantsLoading={
            connectedRestaurantsLoading || searchConnectedRestaurantsLoading
          }
          partnerGuid={partnerGuid}
          tableParams={tableParams}
          setTableParams={setTableParams}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          debouncedSearchTerm={debouncedSearchTerm}
        />
      )}
    </div>
  )
}
