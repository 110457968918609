import { useNavigate } from 'react-router-dom'

import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading,
  ErrorCallToActionSection,
  ErrorText
} from '@toasttab/buffet-pui-error-pages'
import {
  Permissions403DesktopIllustration,
  Permissions403MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'

export function NotFoundPage() {
  const navigate = useNavigate()

  return (
    <ErrorContainer>
      <div data-testid='custom-404-error-page'>
        <div
          className='hidden md:block visible mx-auto'
          style={{
            maxWidth: 600
          }}
        >
          <Permissions403DesktopIllustration />
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{
            maxWidth: 250
          }}
        >
          <Permissions403MobileIllustration />
        </div>
      </div>
      <div>
        <ErrorHeading>Sorry! This dish is no longer on the menu.</ErrorHeading>
        <ErrorSubheading>
          We don’t have the ingredients to make this anymore.
        </ErrorSubheading>
      </div>
      <ErrorCallToActionSection spacing='flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4'>
        <Button onClick={() => navigate('/home')} variant='primary'>
          Go home
        </Button>
      </ErrorCallToActionSection>
      <div className='space-y-2'>
        <ErrorText>{`404: PAGE NOT FOUND`}</ErrorText>
      </div>
    </ErrorContainer>
  )
}
