import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { GET_PARTNER_TILE_INFO } from '../../api/queries/getPartnerTileInfo'
import './EditListingPage.css'
import { EditListingPageBody } from '../EditListingPageBody/EditListingPageBody'

export const EditListingPage = () => {
  const { partner } = useDepotBanquetProps()

  const {
    data: getPartnerTileInfo,
    loading: getPartnerTileInfoLoading,
    error: getPartnerTileInfoError
  } = useQuery<{ getPartnerInfo: PartnerTileInfo }>(GET_PARTNER_TILE_INFO, {
    context: { headers: { 'toast-organization-guid': partner.guid } },
    fetchPolicy: 'no-cache'
  })

  const partnerTileInfo = useMemo(
    () => getPartnerTileInfo?.getPartnerInfo,
    [getPartnerTileInfo?.getPartnerInfo]
  )

  if (getPartnerTileInfoError) {
    return (
      <div className='bg-white'>
        <ErrorPage500 />
      </div>
    )
  }

  if (getPartnerTileInfoLoading) {
    return (
      <div className='flex flex-row justify-center w-full h-screen'>
        <MerryGoRound
          className='pin-center'
          size='sm'
          testId='credentials-loader'
        />
      </div>
    )
  }

  if (partnerTileInfo) {
    return <EditListingPageBody partnerTileInfo={partnerTileInfo} />
  }

  return null
}
