import { Button } from '@toasttab/buffet-pui-buttons'
import { Panel } from '@toasttab/buffet-pui-config-templates'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { FilterIcon } from '@toasttab/buffet-pui-icons'

export function ActivityLogNoResultFromFilter({
  handleClearFilters
}: {
  handleClearFilters: () => void
}) {
  return (
    <Panel>
      <EmptyState
        title='No activities match those filters'
        className='my-10'
        icon={<FilterIcon accessibility='decorative' />}
        button={
          <Button variant='secondary' onClick={handleClearFilters}>
            Clear search
          </Button>
        }
      >
        Try updating or clearing your filters to see more results
      </EmptyState>
    </Panel>
  )
}
