import {
  HeadingGroup,
  Page,
  PageBody,
  PageHeader,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { PropsWithChildren } from 'react'
import { patternGeneralIllustrationDataUrl } from '@toasttab/buffet-pui-illustrations'

export interface LayoutProps {
  testId?: string | number
  subTitle?: string
  title?: string
}

/**
 * Layout component
 */
export function Layout({
  children,
  testId,
  subTitle,
  title
}: PropsWithChildren<LayoutProps>) {
  return (
    <Page
      className='h-fit w-full bg-no-repeat bg-right bg-gray-0'
      style={{
        backgroundImage: patternGeneralIllustrationDataUrl,
        minHeight: 'calc(100vh - 4rem)'
      }}
      testId={testId}
    >
      <PageHeader>
        <HeadingGroup subTitle={subTitle}>
          <Title>{title}</Title>
        </HeadingGroup>
      </PageHeader>
      <PageBody>{children}</PageBody>
    </Page>
  )
}
