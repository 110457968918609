import { gql } from '@apollo/client'

export const SEARCH_CONNECTED_RESTAURANTS_FOR_PARTNER = gql`
  query searchConnectedRestaurants($term: String!, $page: Int) {
    searchConnectedRestaurants(term: $term, page: $page) {
      lastPageNum
      pageSize
      totalResultCount
      results {
        restaurantGuid
        restaurantName
        restaurantLocationName
        externalRestaurantRef
        externalGroupRef
        userEmail
        createdDate
      }
    }
  }
`
