import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useCallback } from 'react'
import { getFileDisplaySize } from '../utils/getFileDisplaySize/getFileDisplaySize'
import { toBase64 } from '../utils/toBase64/toBase64'
import { validateDimensions } from '../utils/validateDimensions/validateDimensions'
import { validateAccept } from '../utils/validateFileAccept/validateFileAccept'
import { validateMaxSize } from '../utils/validateMaxSize/validateMaxSize'

export function useValidateImage(
  accept?: string,
  maxSize?: number,
  minDimension?: number,
  maxDimension?: number
) {
  const { showErrorSnackBar } = useSnackBar()

  return useCallback(
    async (newFile: File) => {
      if (!validateAccept(newFile, accept)) {
        showErrorSnackBar(
          'Oops! The file type is not to spec. Please upload an image that is either a PNG or SVG',
          { testId: 'image-type-error-snackbar' }
        )
        return false
      }

      if (!validateMaxSize(newFile, maxSize)) {
        showErrorSnackBar(
          `Oops! The image size is not to spec. Please upload an image that has size less then ${getFileDisplaySize(
            maxSize ?? 0
          )}.`,
          { testId: 'image-size-error-snackbar' }
        )
        return false
      }

      let imageBase64: string
      try {
        imageBase64 = await toBase64(newFile)
      } catch (e) {
        showErrorSnackBar(
          `Oops! The image not valid. Please upload a different image.`,
          { testId: 'image-parse-failed-error-snackbar' }
        )
        return false
      }

      const validateDimensionsResult = await validateDimensions(
        imageBase64,
        minDimension,
        maxDimension
      )
      if (!validateDimensionsResult) {
        const minDimensionNA = minDimension ?? 'n/a'
        const maxDimensionNA = maxDimension ?? 'n/a'
        showErrorSnackBar(
          `Oops! The image dimensions are not to spec. Please upload an image that has a minimum dimension of ${minDimensionNA} x ${minDimensionNA} and a maximum dimension of ${maxDimensionNA} x ${maxDimensionNA}.`,
          { testId: 'image-dimensions-error-snackbar' }
        )
        return false
      }

      return imageBase64
    },
    [accept, maxDimension, maxSize, minDimension, showErrorSnackBar]
  )
}
