import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AlertModal, Modal } from '@toasttab/buffet-pui-modal'
import { BackButton } from '@toasttab/buffet-pui-wizard-templates'

type NavigateToProps = {
  navigateTo: string
  onClick?: never
}
type OnClickProps = {
  navigateTo?: never
  onClick: () => void
}

export function BackButtonWithWarning({
  unsavedChanges,
  navigateTo,
  onClick
}: { unsavedChanges?: boolean } & (NavigateToProps | OnClickProps)) {
  const [showFormUpdatedWarning, setShowFormUpdatedWarning] = useState(false)

  const ConditionBackButton = useCallback(() => {
    if (unsavedChanges) {
      return <BackButton onClick={() => setShowFormUpdatedWarning(true)} />
    }

    if (navigateTo) {
      return <BackButton as={Link} to={navigateTo} />
    }
    if (onClick) {
      return <BackButton onClick={onClick} />
    }

    return null
  }, [navigateTo, onClick, unsavedChanges])

  const DiscardButton = useCallback(() => {
    if (navigateTo) {
      return (
        <Button
          as={Link}
          to={navigateTo}
          variant='secondary'
          testId='warning-modal-discard'
        >
          Discard
        </Button>
      )
    }
    if (onClick) {
      return (
        <Button
          variant='secondary'
          onClick={onClick}
          testId='warning-modal-discard'
        >
          Discard
        </Button>
      )
    }

    return null
  }, [navigateTo, onClick])

  return (
    <>
      <ConditionBackButton />
      <AlertModal
        testId='unsaved-changes-warning'
        isOpen={showFormUpdatedWarning}
        onRequestClose={() => setShowFormUpdatedWarning(false)}
      >
        <Modal.Header>You have unsaved changes on the form</Modal.Header>
        <Modal.Body>
          <p>Cancel without saving?</p>
        </Modal.Body>
        <Modal.Footer>
          <DiscardButton />
          <Button
            onClick={() => setShowFormUpdatedWarning(false)}
            testId='warning-modal-cancel'
          >
            Cancel
          </Button>
        </Modal.Footer>
      </AlertModal>
    </>
  )
}
