export interface PartnerTileInfo {
  __typename?: 'PartnerTileInfo'
  additionalFee: string | null
  adminRemoveOnly: boolean
  applicationName: string
  availableForToastNow: boolean
  categories: Category[]
  channel: string
  clientId: string
  connectRequired: boolean
  countries: string[]
  createdBy: string
  createdDate: string
  deleted: boolean
  deletedDate: string | null
  description: string
  featureId: string
  guid: string
  hasLocalChanges: boolean
  iconUrl: string | null
  infoUrl: string
  isDeletedLocally: boolean
  isThirdPartyOrderPartner: boolean
  mainUrl: string
  marketImageUrl: string | null
  modifiedBy: string
  modifiedDate: string
  name: string
  needsAccountVerification: boolean
  onboardingEmail: string
  originalIconUrl: string | null
  partnerBehaviour: string
  partnerType: string
  preferred: boolean
  primaryAction: string
  primaryActionReference: string
  primaryCustomCtaLabel: string
  requiredFeatures: string[]
  secondaryAction: string | null
  secondaryActionReference: string | null
  secondaryCustomCtaLabel: string | null
  signUpUrl: string | null
  supportEmail: string | null
  supportPhone: string | null
  version: number
  visible: boolean
}

export interface Category {
  __typename?: 'Category'
  description: string
  guid: string
  label: string
}

export const partnerActionLabelFromValue = (
  partnerActionValue: string
): string => {
  return partnerActionValue === 'ADD_CONNECT_INTEGRATION'
    ? 'Add Now'
    : 'Learn More'
}
