import { gql } from '@apollo/client'

export const PUT_PARTNER_TILE_INFO = gql`
  mutation updatePartner($newPartner: PartnerTileInfoInput!) {
    updatePartner(newPartner: $newPartner) {
      guid
      description
      iconUrl
    }
  }
`
