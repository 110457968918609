import {
  CreatingIllustration,
  OrderingOnlineIllustration,
  PrepRecipeIllustration,
  PayrollMobileIllustration,
  TabletTeamIllustration,
  UnderReviewIllustration
} from '@toasttab/buffet-pui-illustrations'
import { HomepageCardProps } from './components/HomepageCard'

export const homepageResources: Readonly<{
  [key in string]: HomepageCardProps
}> = Object.freeze({
  COOKBOOKS: {
    title: 'Cookbooks',
    description: `Use these start-to-finish recipes to get your integration implemented and live. Follow best practices and make sure you don't miss a step.`,
    actionTitle: 'View cookbooks',
    actionLink: 'https://doc.toasttab.com/doc/cookbook/index.html',
    imageComponent: <PrepRecipeIllustration resizeToContainer />
  },
  DEVELOPER_GUIDE: {
    title: 'Developer guide',
    description: `Use these guides to help you use Toast APIs and build a successful integration with the Toast platform.`,
    actionTitle: 'View developer guide',
    actionLink: 'https://doc.toasttab.com/doc/devguide/index.html',
    imageComponent: <PayrollMobileIllustration resizeToContainer />
  },
  API_REFERENCE: {
    title: 'API reference',
    description: `The Toast API reference includes detailed information about the endpoints, parameters, responses, and data types for Toast APIs.`,
    actionTitle: 'View API reference',
    actionLink: 'https://doc.toasttab.com/openapi/',
    imageComponent: <OrderingOnlineIllustration resizeToContainer />
  },
  PLATFORM_GUIDE: {
    title: 'Platform guide',
    description: ` Use these detailed guides to learn about the functionality of the Toast platform that you can use to integrate Toast platform products and features with your business operations.`,
    actionTitle: 'View platform guide',
    actionLink: 'https://doc.toasttab.com/doc/platformguide/index.html',
    imageComponent: <CreatingIllustration resizeToContainer />
  },
  RELEASE_NOTES: {
    title: 'Release notes',
    description: `View release notes about changes in Toast APIs and the Toast platform.`,
    actionTitle: 'View release notes',
    actionLink: 'https://doc.toasttab.com/doc/relnotes/index.html',
    imageComponent: <UnderReviewIllustration resizeToContainer />
  },
  COMMUNITY: {
    title: 'Community',
    description: `View announcements, request documentation or new features and get support.`,
    actionTitle: 'View community',
    actionLink: 'https://developers.toasttab.com/hc/en-us/community/topics',
    imageComponent: <TabletTeamIllustration resizeToContainer />
  }
})
