import { Dispatch, SetStateAction } from 'react'
import { DateTime } from 'luxon'
import { DateRangePicker } from '@toasttab/buffet-pui-date-picker'
import { DateRange } from 'react-day-picker'
import { TableParams } from '../types'

export interface FiltersProps {
  testId?: string | number
  tableParams: TableParams
  setTableParams: Dispatch<SetStateAction<TableParams>>
}

export const Filters = ({
  testId,
  tableParams,
  setTableParams
}: FiltersProps) => {
  let currentValue = {
    from: tableParams?.createdFrom,
    to: tableParams?.createdTo
  }

  const disabledDays = {
    before: DateTime.local().minus({ years: 10 }).toJSDate(),
    after: DateTime.local().toJSDate()
  }

  const handleApplyFilters = (
    value: DateRange | undefined,
    selectedDate?: Date
  ) => {
    if (currentValue.from && currentValue.to) {
      currentValue.from = selectedDate
      currentValue.to = undefined
    } else if (value?.from && value?.to) {
      const createdFrom = value.from
      const createdTo = DateTime.fromJSDate(value.to)
        .plus({ hours: 23, minutes: 59 })
        .toJSDate()

      setTableParams({
        ...tableParams,
        createdFrom,
        createdTo
      })
    } else {
      currentValue.from = selectedDate
    }
  }

  return (
    <div data-testid={testId} className='flex flex-row items-center'>
      <DateRangePicker
        testId='dateRange-filter'
        containerClassName='m-2 w-80'
        name='dateRange'
        label='Connection date range'
        value={currentValue}
        numberOfMonths={2}
        defaultMonth={DateTime.local().minus({ month: 1 }).toJSDate()}
        disabledDays={disabledDays}
        onChange={handleApplyFilters}
      />
    </div>
  )
}
