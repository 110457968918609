import { Dispatch, SetStateAction } from 'react'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import {
  RestaurantIcon,
  AutorenewIcon,
  LookupInfoIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { SearchParams, TableParams } from '../types'
import { initialSearchParams } from '../ConnectedRestaurants'

export interface NoRestaurantsMessageProps {
  setSearchParams: Dispatch<SetStateAction<SearchParams>>
  tableParams: TableParams
  setTableParams: Dispatch<SetStateAction<TableParams>>
  partnerDoesNotHaveRestaurants: boolean
}

export const NoRestaurantsMessage = ({
  setSearchParams,
  tableParams,
  setTableParams,
  partnerDoesNotHaveRestaurants
}: NoRestaurantsMessageProps) => {
  const resetSearchAndFilters = () => {
    setTableParams({
      ...tableParams,
      createdFrom: undefined,
      createdTo: undefined
    })
    setSearchParams(initialSearchParams)
  }

  const getErrorMessage = () => {
    if (partnerDoesNotHaveRestaurants) {
      return {
        title: 'No connected restaurants...yet',
        subheading:
          'Once a restaurant adds your integration, their information will appear here.',
        icon: <RestaurantIcon accessibility='decorative' />,
        button: (
          <Button
            className='w-80 mt-5 mb-2'
            variant='primary'
            iconLeft={<AutorenewIcon accessibility='decorative' />}
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        )
      }
    } else {
      return {
        title: `No restaurants match your search`,
        subheading: 'Try updating or clearing your search to find restaurants.',
        icon: <LookupInfoIcon accessibility='decorative' />,
        button: (
          <Button variant='secondary' onClick={() => resetSearchAndFilters()}>
            Clear search & filters
          </Button>
        )
      }
    }
  }

  const { title, subheading, button, icon } = getErrorMessage()

  return (
    <CardContainer>
      <EmptyState className='my-10' button={button} title={title} icon={icon}>
        {subheading}
      </EmptyState>
    </CardContainer>
  )
}
