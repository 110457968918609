import { useMemo, useState } from 'react'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { Page } from '@toasttab/buffet-pui-config-templates'
import {
  PageContainer,
  PageStepper
} from '@toasttab/buffet-pui-wizard-templates'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { StepState } from '../../types/stepState.type'
import { RenderCurrentStepBody } from './RenderCurrentStepBody'
import { getStepState, MarketplaceListingSteps } from './stepperUtilities'

export function EditListingPageBody({
  partnerTileInfo
}: {
  partnerTileInfo: PartnerTileInfo
}) {
  const screenSize = useScreenSize()
  const isMobile = screenSize < ScreenSize.MD
  const isXs = screenSize === ScreenSize.XS

  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [currentStepHasError, setCurrentStepHasError] = useState(false)

  const pageStepperSteps: {
    state: StepState
    title?: string
  }[] = useMemo(
    () =>
      MarketplaceListingSteps.map((step, idx) => ({
        state: getStepState(currentStepIdx, idx, currentStepHasError, step),
        title: isXs ? undefined : step
      })),
    [currentStepHasError, currentStepIdx, isXs]
  )

  const currentStep = useMemo(
    () => MarketplaceListingSteps[currentStepIdx],
    [currentStepIdx]
  )

  return (
    <Page testId='edit-listing-page' disableMaxWidth>
      <PageContainer containerClassName='!pt-0'>
        <PageStepper
          type='icon'
          orientation={
            isMobile ? 'horizontal-text-below' : 'horizontal-text-side'
          }
          size={isMobile ? 'sm' : 'default'}
          steps={pageStepperSteps}
        />
        <RenderCurrentStepBody
          partnerTileInfo={partnerTileInfo}
          currentStep={currentStep}
          setCurrentStepIdx={setCurrentStepIdx}
          setCurrentStepHasError={setCurrentStepHasError}
        />
      </PageContainer>
    </Page>
  )
}
