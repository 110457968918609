import { useCallback, useState } from 'react'
import { FormikProps } from 'formik'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { useUploadImage } from '../../api/hooks/useUploadImage'

export function useDepotImageFiled(
  setFormFieldValue: FormikProps<
    Pick<PartnerTileInfo, 'iconUrl' | 'originalIconUrl'>
  >['setFieldValue']
) {
  const [imageUploading, setImageUploading] = useState(false)

  const handleRemoveExistingImage = useCallback(() => {
    setFormFieldValue('iconUrl', null)
    setFormFieldValue('originalIconUrl', null)
  }, [setFormFieldValue])

  const { uploadImageMutation } = useUploadImage()

  const uploadImage = useCallback(
    async (imageBase64: string) => {
      const resp = await uploadImageMutation({
        variables: {
          image: imageBase64.split(',')[1],
          metadata: imageBase64.split(',')[0]
        }
      })
      const uploadedResult = resp?.data?.uploadImage
      if (
        uploadedResult &&
        uploadedResult.imageUrl &&
        uploadedResult.originalImageUrl
      ) {
        setFormFieldValue('iconUrl', uploadedResult.imageUrl)
        setFormFieldValue('originalIconUrl', uploadedResult.originalImageUrl)
      }
    },
    [setFormFieldValue, uploadImageMutation]
  )

  return {
    imageUploading,
    setImageUploading,
    uploadImage,
    handleRemoveExistingImage
  }
}
