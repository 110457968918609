import { gql } from '@apollo/client'

export const ROTATE_CLIENT_SECRET = gql`
  mutation rotateClientSecretV2($clientId: ID!) {
    rotateClientSecretV2(clientId: $clientId) {
      clientSecret
      rotateInfo {
        createdAt
        performedByEmail
      }
    }
  }
`
