import { createColumnHelper } from '@tanstack/react-table'
import { ActivityLog } from '../../../types'
import { getFormattedText } from './getFormattedText'

export const columnHeaderMap: { [column in keyof ActivityLog]: string } = {
  timestamp: 'Timestamp',
  userEmail: 'User Email',
  action: 'Action'
}

const columnHelper = createColumnHelper<ActivityLog>()

export const activityLogColumns = [
  columnHelper.accessor('timestamp', {
    header: columnHeaderMap.timestamp,
    cell: (info) => getFormattedText('timestamp', info.getValue())
  }),
  columnHelper.accessor('userEmail', {
    header: columnHeaderMap.userEmail
  }),
  columnHelper.accessor('action', {
    header: columnHeaderMap.action,
    cell: (info) => getFormattedText('action', info.getValue())
  })
]
