export function validateAccept(file: File, accept: string = ''): boolean {
  if (!file || !(file instanceof File)) return false

  if (!accept || accept === '*' || accept === '*/*') {
    return true
  }

  const options = accept.split(',')

  const [fileBaseMimeType, fileType] = file.type.toLowerCase().split('/')

  return options.some((option) => {
    const typeOrExt = option.toLowerCase().trim()

    if (
      typeOrExt.startsWith('.') &&
      file.name.toLowerCase().endsWith(typeOrExt)
    ) {
      return true
    } else {
      const [baseMimeType, type] = typeOrExt.split('/')

      return (
        baseMimeType === fileBaseMimeType && (type === '*' || type === fileType)
      )
    }
  })
}
