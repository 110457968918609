import { Dispatch, SetStateAction, useEffect } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { TextInputField, TextareaField } from '@toasttab/buffet-pui-forms'
import {
  ContentFooter,
  ContentHeader,
  ContinueButton,
  NavigationContainer,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { DepotImageInput } from '@toasttab/depot-image-input/src'
import { useUpdatePartnerTile } from '../../api/hooks/useUpdatePartnerTile'
import {
  PartnerTileInfo,
  partnerActionLabelFromValue
} from '../../types/partnerInfo.type'
import {
  descriptionMaxLength,
  MarketplaceListingSchema
} from '../../utils/validation'
import { BackButtonWithWarning } from '../BackButtonWithWarning/BackButtonWithWarning'
import { CustomPageAside } from '../CustomPageAside/CustomPageAside'
import { PageAsidePreviewIntegrationTile } from '../PageAsidePreviewIntegrationTile/PageAsidePreviewIntegrationTile'
import { Action } from '../PreviewIntegrationTile/PreviewIntegrationTile'
import { useDepotImageFiled } from '../useDepotImageField/useDepotImageField'
import { OpenSupportTicketHelperText } from '../EditListingPageBody/OpenSupportTicketHelperText'

// 2mb
const defaultAppLogoMaxSize = 2 * 1024 * 1024

export function MarketplaceListingInfoForm({
  partnerTileInfo,
  handleGoBack,
  handleContinue,
  hasChanges,
  setCurrentStepHasError
}: {
  partnerTileInfo: PartnerTileInfo
  handleGoBack: (values: PartnerTileInfo) => void
  handleContinue: (values: PartnerTileInfo) => void
  hasChanges: (currentValues: PartnerTileInfo) => boolean
  setCurrentStepHasError: Dispatch<SetStateAction<boolean>>
}) {
  const { updatePartnerTileInfo } = useUpdatePartnerTile()

  const form = useFormik<PartnerTileInfo>({
    initialValues: partnerTileInfo,
    onSubmit: async (values) => {
      await updatePartnerTileInfo(values)
      handleContinue(values)
    },
    // This is needed to handle the custom helper text and show the error message as soon as the input is invalid.
    initialTouched: { description: true },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: MarketplaceListingSchema
  })

  useEffect(() => {
    setCurrentStepHasError(!!Object.keys(form.errors).length)
  }, [form.errors, setCurrentStepHasError])

  const {
    imageUploading,
    setImageUploading,
    uploadImage,
    handleRemoveExistingImage
  } = useDepotImageFiled(form.setFieldValue)

  return (
    <PageBody>
      <PageMain>
        <ContentHeader title='Marketplace listing' contentDivider />
        <FormikProvider value={form}>
          <Form className='gap-4 flex flex-col'>
            <DepotImageInput
              id='application logo'
              testId='application-logo'
              wrapperClassNames='gap-2 max-w-sm'
              label='Application logo'
              helperText='PNG or SVG file. Minimum dimensions 512 x 512. Maximum 1024 x 1024'
              existingImageUrl={form.values.iconUrl}
              imageUploading={imageUploading}
              setImageUploading={setImageUploading}
              uploadImage={uploadImage}
              removeExistingImage={handleRemoveExistingImage}
              accept='image/svg+xml,image/png'
              maxSize={defaultAppLogoMaxSize}
              minDimension={512}
              maxDimension={1024}
            />
            <TextareaField
              containerClassName='w-full'
              label={'Short description'}
              required
              aria-required
              name='description'
              testId='description'
              placeholder='Type here'
              maxChars={descriptionMaxLength}
              maxCharsHelperTextFunc={(numChars, maxChars) => (
                <span>
                  Explain what your company and/or integration does for Toast
                  customers. (
                  <span style={{ fontVariantNumeric: 'tabular-nums' }}>
                    {`${numChars}/${maxChars} `}
                  </span>
                  characters)
                </span>
              )}
            />
            <TextInputField
              name='primaryAction'
              label='Primary Action Label'
              testId='primaryAction-field'
              value={
                !!partnerTileInfo.primaryCustomCtaLabel
                  ? partnerTileInfo.primaryCustomCtaLabel
                  : partnerActionLabelFromValue(partnerTileInfo.primaryAction)
              }
              disabled
              helperText={
                <OpenSupportTicketHelperText fieldDescription='primary action' />
              }
            />
            {partnerTileInfo.primaryActionReference && (
              <TextInputField
                name='primaryActionReference'
                label='Primary Action Reference'
                testId='primaryActionReference-field'
                disabled
                helperText={
                  <OpenSupportTicketHelperText fieldDescription='primary action reference' />
                }
              />
            )}
            {partnerTileInfo.secondaryAction && (
              <TextInputField
                name='secondaryAction'
                label='Secondary Action Label'
                testId='secondaryAction-field'
                value={
                  !!partnerTileInfo.secondaryCustomCtaLabel
                    ? partnerTileInfo.secondaryCustomCtaLabel
                    : partnerActionLabelFromValue(
                        partnerTileInfo.secondaryAction
                      )
                }
                disabled
                helperText={
                  <OpenSupportTicketHelperText fieldDescription='secondary action' />
                }
              />
            )}
            {partnerTileInfo.secondaryAction &&
              partnerTileInfo.secondaryActionReference && (
                <TextInputField
                  name='secondaryActionReference'
                  label='Secondary Action Reference'
                  testId='secondaryActionReference-field'
                  disabled
                  helperText={
                    <OpenSupportTicketHelperText fieldDescription='secondary action reference' />
                  }
                />
              )}
            <ContentFooter>
              <NavigationContainer>
                <BackButtonWithWarning
                  onClick={() => handleGoBack(form.values)}
                />
                <ContinueButton
                  as='button'
                  disabled={
                    !hasChanges(form.values) ||
                    !form.isValid ||
                    form.isSubmitting ||
                    imageUploading
                  }
                  testId='submit-listing-button'
                  type='submit'
                >
                  Submit
                </ContinueButton>
              </NavigationContainer>
            </ContentFooter>
          </Form>
        </FormikProvider>
      </PageMain>
      <CustomPageAside
        testId='listing-page-aside'
        topContent={
          <PageAsidePreviewIntegrationTile
            iconUrl={form.values.iconUrl}
            name={partnerTileInfo.name}
            description={form.values.description}
            partnerIntegrationCategories={partnerTileInfo.categories}
            primaryAction={partnerTileInfo.primaryAction as Action}
            primaryCustomCtaLabel={partnerTileInfo.primaryCustomCtaLabel}
            secondaryAction={partnerTileInfo.secondaryAction as Action}
            secondaryCustomCtaLabel={partnerTileInfo.secondaryCustomCtaLabel}
          />
        }
      />
    </PageBody>
  )
}
