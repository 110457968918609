import { Button } from '@toasttab/buffet-pui-buttons'
import { CopyIcon } from '@toasttab/buffet-pui-icons'
import copy from 'copy-to-clipboard'

export interface CopyButtonProps {
  testId?: string | number
  copyText: string
}

export const CopyButton = ({
  testId = `copy-button`,
  copyText
}: CopyButtonProps) => (
  <Button
    variant='link'
    onClick={() => copy(copyText)}
    iconLeft={<CopyIcon className='text-link' accessibility='decorative' />}
    testId={testId}
  >
    Copy
  </Button>
)
