import {
  HeadingCell,
  Head,
  Row,
  SortIndicator,
  SortDirection
} from '@toasttab/buffet-pui-table'
import { Table, flexRender, Column } from '@tanstack/react-table'

export interface TableHeadersProps<T> {
  table: Table<T>
}

export function TableHeaders<T>({ table }: TableHeadersProps<T>) {
  const getSortDirection = (column: Column<T, unknown>) => {
    if (!column.getIsSorted()) return SortDirection.NONE

    return column.getIsSorted() === 'asc'
      ? SortDirection.ASC
      : SortDirection.DESC
  }

  return (
    <Head>
      {table.getHeaderGroups().map((headerGroup) => (
        <Row key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <HeadingCell
                key={header.id}
                className='type-default align-center'
              >
                {header.isPlaceholder ? null : (
                  <>
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <SortIndicator
                        className='w-2 h-full'
                        direction={getSortDirection(header.column)}
                      />
                    </div>
                  </>
                )}
              </HeadingCell>
            )
          })}
        </Row>
      ))}
    </Head>
  )
}
