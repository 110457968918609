import { Dispatch, SetStateAction, useEffect } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import {
  ContentFooter,
  ContentHeader,
  ContinueButton,
  NavigationContainer,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { PartnerTileInfo } from '../../types/partnerInfo.type'
import { BackButtonWithWarning } from '../BackButtonWithWarning/BackButtonWithWarning'
import { CustomPageAside } from '../CustomPageAside/CustomPageAside'
import { SupportSectionSchema } from '../../utils/validation'

export const phoneNumberHelperText =
  'Phone number that Toast will prompt customer to contact if they need help with the integration'
export const supportEmailHelperText =
  'Email that Toast will prompt customer to contact if they need help with the integration'
export const mainUrlHelperText =
  'Customer-facing webpage that contains more information about the Partner and Application'

export function SupportInfoForm({
  partnerTileInfo,
  handleGoBack,
  handleContinue,
  setCurrentStepHasError
}: {
  partnerTileInfo: PartnerTileInfo
  handleGoBack: (values: PartnerTileInfo) => void
  handleContinue: (value: Partial<PartnerTileInfo>) => void
  setCurrentStepHasError: Dispatch<SetStateAction<boolean>>
}) {
  const form = useFormik<PartnerTileInfo>({
    initialValues: partnerTileInfo,
    onSubmit: (value) => {
      handleContinue({
        supportPhone: value.supportPhone,
        supportEmail: value.supportEmail,
        mainUrl: value.mainUrl
      })
    },
    initialTouched: { supportEmail: true, supportPhone: true, mainUrl: true },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: SupportSectionSchema
  })

  useEffect(() => {
    setCurrentStepHasError(!!Object.keys(form.errors).length)
  }, [form.errors, setCurrentStepHasError])

  return (
    <PageBody>
      <PageMain>
        <ContentHeader title='Support' contentDivider />
        <FormikProvider value={form}>
          <Form className='gap-4 flex flex-col'>
            <TextInputField
              name='supportPhone'
              label='Support phone number'
              testId='support-phone'
              helperText={phoneNumberHelperText}
              placeholder='+1 (123) 456 7890'
            />
            <TextInputField
              label='Company website URL'
              name='mainUrl'
              testId='main-url'
              placeholder='http://www.example.com'
              helperText={mainUrlHelperText}
              required
            />
            <TextInputField
              name='supportEmail'
              label='Support email address'
              testId='support-email'
              helperText={supportEmailHelperText}
            />
            <ContentFooter>
              <NavigationContainer>
                <BackButtonWithWarning
                  onClick={() => handleGoBack(form.values)}
                />
                <ContinueButton
                  as='button'
                  disabled={!form.isValid || form.isSubmitting}
                  testId='submit-about-your-app-button'
                  type='submit'
                >
                  Continue
                </ContinueButton>
              </NavigationContainer>
            </ContentFooter>
          </Form>
        </FormikProvider>
      </PageMain>
      <CustomPageAside testId='listing-page-aside' />
    </PageBody>
  )
}
