import { captureException, withScope } from '@sentry/browser'
import { track } from '@toasttab/use-heap'
import fileDownload from 'js-file-download'

import { SearchParams, TableParams } from '../types'

export const EXPORT_CONNECTED_RESTAURANTS_FILTERS_URL =
  '/api/service/partners/v1/developer/application/restaurants/export'

export const EXPORT_CONNECTED_RESTAURANTS_SEARCH_URL =
  '/api/service/partners/v1/developer/application/restaurants/search/export'

const CONNECTED_RESTAURANTS_REPORT = 'Connected Restaurants Report.csv'

export const exportConnectedRestaurantsResult = (
  partnerGuid: string,
  tableParams: TableParams,
  searchParams: SearchParams
) => {
  if (searchParams.term !== '') {
    exportConnectedRestaurantsSearchResult(partnerGuid, {
      term: searchParams.term
    })
  } else {
    exportConnectedRestaurantsFiltersResult(
      partnerGuid,
      getFilters(tableParams)
    )
  }
}

const exportConnectedRestaurantsFiltersResult = async (
  partnerGuid: string,
  params: Record<string, string>
) => {
  track('TPC_DEPOT_USER_REQUEST_RESTAURANT_FILTER_RESULT')
  try {
    const data = await fetch(
      EXPORT_CONNECTED_RESTAURANTS_FILTERS_URL +
        '?' +
        new URLSearchParams(params),
      {
        headers: { 'toast-organization-guid': partnerGuid }
      }
    ).then((response) => response.text())
    fileDownload(data, CONNECTED_RESTAURANTS_REPORT)
    track('TPC_DEPOT_RESTAURANT_FILTER_RESULT_EXPORT_SUCCESSFUL', {
      filename: CONNECTED_RESTAURANTS_REPORT
    })
  } catch (error) {
    track('TPC_DEPOT_RESTAURANT_FILTER_RESULT_EXPORT_FAILED', {
      error: error as string
    })
    handleError(error, 'exportConnectedRestaurantsFiltersResult')
  }
}

const exportConnectedRestaurantsSearchResult = async (
  partnerGuid: string,
  params: Record<string, string>
) => {
  track('TPC_DEPOT_USER_REQUEST_RESTAURANT_SEARCH_RESULT')
  try {
    const data = await fetch(
      EXPORT_CONNECTED_RESTAURANTS_SEARCH_URL +
        '?' +
        new URLSearchParams(params),
      {
        headers: { 'toast-organization-guid': partnerGuid }
      }
    ).then((response) => response.text())
    fileDownload(data, CONNECTED_RESTAURANTS_REPORT)
    track('TPC_DEPOT_RESTAURANT_SEARCH_RESULT_EXPORT_SUCCESSFUL', {
      filename: CONNECTED_RESTAURANTS_REPORT
    })
  } catch (error) {
    track('TPC_DEPOT_RESTAURANT_SEARCH_RESULT_EXPORT_FAILED', {
      error: error as string
    })
    handleError(error, 'exportConnectedRestaurantsSearchResult')
  }
}

const handleError = (error: unknown, error_method: string) => {
  withScope((scope) => {
    scope.setTag('toast_component', 'exportConnectedRestaurants')
    scope.setTag('toast_function', error_method)
    scope.setLevel('error')
    captureException(error)
  })

  window.alert('Something went wrong. Please try again!')
}

const getFilters = (tableParams: TableParams) => {
  const filters: Record<string, string> = {}

  if (tableParams.createdFrom) {
    filters.createdFrom = tableParams.createdFrom.toISOString()
  }

  if (tableParams.createdTo) {
    filters.createdTo = tableParams.createdTo.toISOString()
  }

  if (tableParams.sortField) {
    filters.sortField = tableParams.sortField
  }

  if (tableParams.sortDirection) {
    filters.sortDirection = tableParams.sortDirection
  }

  return filters
}
