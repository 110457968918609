import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Image } from '@toasttab/buffet-pui-image'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { partnerActionLabelFromValue } from '../../types/partnerInfo.type'

export type Action = 'ADD_CONNECT_INTEGRATION' | 'URI_LINK'

export interface PreviewIntegrationTileProps {
  iconUrl: string | null
  name: string
  description: string
  categories: {
    guid: string
    label?: string
  }[]
  primaryAction: Action
  primaryCustomCtaLabel: string
  secondaryAction: Action | null
  secondaryCustomCtaLabel: string | null
}

/**
 * IntegrationTile component
 */
export const PreviewIntegrationTile = ({
  iconUrl,
  name,
  description,
  categories,
  primaryAction,
  primaryCustomCtaLabel,

  secondaryAction,
  secondaryCustomCtaLabel
}: PreviewIntegrationTileProps) => {
  return (
    <CardContainer
      testId='preview-integration-tile'
      className='w-full flex flex-col gap-3'
    >
      <Image
        src={iconUrl || ''}
        alt='partner-logo'
        containerClassName='w-24 h-24 rounded'
        className='w-full h-auto'
      />
      <div className='type-headline-5 font-bold text-primary'>{name}</div>
      <div className='type-default text-secondary line-clamp-3'>
        {description}
      </div>
      {categories.length > 0 && (
        <div>
          <span className='type-caption font-semibold'>Categories: </span>
          <span className='type-caption text-secondary'>
            {categories?.map((category) => category.label)?.join(', ') ?? 'N/A'}
          </span>
        </div>
      )}
      <ButtonGroup direction='vertical'>
        <Tooltip testId='primary-action-tooltip' placement='bottom'>
          <Tooltip.Trigger>
            <Button
              testId='primary-action-button'
              variant='primary'
              className='w-full'
            >
              {!!primaryCustomCtaLabel
                ? primaryCustomCtaLabel
                : partnerActionLabelFromValue(primaryAction)}
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content testId='primary-action-tooltip-content'>
            Buttons and links are disabled in preview
          </Tooltip.Content>
        </Tooltip>
        {secondaryAction && (
          <Tooltip placement='bottom'>
            <Tooltip.Trigger>
              <Button
                testId='secondary-action-button'
                variant='link'
                className='w-full'
              >
                {!!secondaryCustomCtaLabel
                  ? secondaryCustomCtaLabel
                  : partnerActionLabelFromValue(secondaryAction)}
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content testId='secondary-action-tooltip-content'>
              Buttons and links are disabled in preview
            </Tooltip.Content>
          </Tooltip>
        )}
      </ButtonGroup>
    </CardContainer>
  )
}
