import cx from 'classnames'
import { DragEvent, PropsWithChildren, useState } from 'react'

type ImageInputContainerProps = PropsWithChildren<{
  testId?: string
  wrapperClassNames?: string
  disabled?: boolean
  onChange: (newFile: File) => void
}>

export function ImageInputContainer({
  testId = 'image-input-container',
  wrapperClassNames,
  children,
  disabled,
  onChange
}: ImageInputContainerProps) {
  const [isDragging, setDragging] = useState(false)

  const dragEnterOver = (event: DragEvent) => {
    if (!disabled) {
      event.preventDefault()
      event.stopPropagation()
      setDragging(true)
    }
  }

  const fileDrop = (dropEvent: DragEvent<HTMLDivElement>) => {
    dropEvent.preventDefault()
    dropEvent.stopPropagation()

    if (!disabled) {
      setDragging(false)
      // only allow 1 file for now
      onChange(dropEvent.dataTransfer.files[0])
    }
  }

  return (
    <div
      data-testid={testId}
      className={cx(
        'flex flex-col bg-white rounded-lg xxl:p-6 p-4 border-2 border-dashed justify-center items-center transition-all',
        wrapperClassNames,
        { 'border-primary-75': isDragging }
      )}
      onDragEnter={dragEnterOver}
      onDragLeave={() => setDragging(false)}
      onDragOver={dragEnterOver}
      onDrop={fileDrop}
    >
      {children}
    </div>
  )
}
