import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import getLocales from '../getLocales'

/**
 * format unix timestamp (in milliseconds) to en-US format in users local timezone
 * @param createdAt e.g.1701362043915
 * @returns e.g.'11/30/2023, 4:34 PM(GMT+0)'
 */
export function formatClientSecretCreatedAt(createdAt: number): string {
  const dateTimeShort = formatDatetimeShort(createdAt)
  if (dateTimeShort) {
    return `${dateTimeShort} (${formatTimeLong(createdAt)})`
  }
  return ''
}

function formatDatetimeShort(createdAt: number): string {
  return format(new Date(createdAt), Formats.dateTime.short, getLocales()) ?? ''
}
function formatTimeLong(createdAt: number): string {
  return format(new Date(createdAt), Formats.timeZone.short, getLocales()) ?? ''
}
