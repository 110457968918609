import { PartnerTileInfo } from '../../types/partnerInfo.type'
import {
  PreviewIntegrationTile,
  PreviewIntegrationTileProps
} from '../PreviewIntegrationTile/PreviewIntegrationTile'

export function PageAsidePreviewIntegrationTile({
  iconUrl,
  name,
  description,
  partnerIntegrationCategories,

  primaryAction,
  primaryCustomCtaLabel,

  secondaryAction,
  secondaryCustomCtaLabel
}: Omit<PreviewIntegrationTileProps, 'categories'> & {
  partnerIntegrationCategories: PartnerTileInfo['categories']
}) {
  return (
    <>
      <div className='flex gap-8 flex-col max-w-xs items-center'>
        <p>This is a preview of your integration listing</p>
        <PreviewIntegrationTile
          iconUrl={iconUrl}
          name={name}
          description={description}
          categories={partnerIntegrationCategories}
          primaryAction={primaryAction}
          primaryCustomCtaLabel={primaryCustomCtaLabel}
          secondaryAction={secondaryAction}
          secondaryCustomCtaLabel={secondaryCustomCtaLabel}
        />
      </div>
    </>
  )
}
