import { gql } from '@apollo/client'

export const GET_CREDENTIALS = gql`
  query getCredentials {
    getCredentials {
      name
      clientId
      scopes
    }
  }
`
