export function OpenSupportTicketHelperText({
  fieldDescription
}: {
  fieldDescription: string
}) {
  return (
    <span className='type-caption text-secondary'>
      If you need to change your {`${fieldDescription}`}, please{' '}
      <a
        href='https://developers.toasttab.com/hc/en-us/requests/new'
        target='_blank'
        className='inline-link'
        rel='noreferrer'
      >
        open a ticket
      </a>{' '}
      with Toast Developer Relations
    </span>
  )
}
