import { gql } from '@apollo/client'

export const GET_ACTIVITY_LOG = gql`
  query getActivityLog {
    getActivityLog {
      userEmail
      action
      timestamp
    }
  }
`
