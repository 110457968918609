import { gql } from '@apollo/client'

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: String!, $metadata: String!) {
    uploadImage(image: $image, metadata: $metadata) {
      imageUrl
      originalImageUrl
    }
  }
`
