import { Link } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { patternGeneralIllustrationDataUrl } from '@toasttab/buffet-pui-illustrations'
import {
  Page,
  PageBody,
  PageHeader,
  Panel,
  Title
} from '@toasttab/buffet-pui-config-templates'
import landingImage from '../../assets/landingImage.png'

export interface MarketplaceListingLandingPageProps {
  testId?: string
}

export const MarketplaceListingLandingPage = ({
  testId = 'marketplace-landing-page'
}: MarketplaceListingLandingPageProps) => {
  return (
    <Page
      className='bg-gray-0 min-h-screen'
      testId={testId}
      style={{
        backgroundImage: patternGeneralIllustrationDataUrl,
        minHeight: 'calc(100vh - 4rem)'
      }}
    >
      <PageHeader isUsingTabs className='block'>
        <div>
          <Title testId='listing-landing-title' className='pt-6 mb-4'>
            Toast Partner Integrations marketplace listing
          </Title>
          <div
            data-testid='listing-landing-subtitle'
            className='type-default text-secondary'
          >
            Manage the appearance and details of your listing on the Toast
            Partner Integrations Marketplace
          </div>
        </div>
      </PageHeader>
      <PageBody>
        <Panel
          className='h-96 mt-8 flex flex-col justify-around items-start'
          style={{
            backgroundImage: `url(${landingImage})`,
            backgroundSize: `contain`,
            backgroundRepeat: `no-repeat`,
            backgroundPositionX: `right`
          }}
        >
          <div>
            <h4 className='type-headline-4 mb-4'>Your listing is live</h4>
            <p className='type-default text-secondary'>
              Your integration listing is visible on Toast Partner Integrations
              marketplace.
            </p>
          </div>
          <Button
            testId='edit-details'
            variant={Button.Variant?.primary}
            iconLeft={<EditIcon accessibility='decorative' />}
            as={Link}
            to={`/marketplace-listing/edit`}
          >
            Edit details
          </Button>
        </Panel>
      </PageBody>
    </Page>
  )
}
