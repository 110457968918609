import { ActivityLogs } from '../../../types'
import { getTimestampSecond } from '../../../utils/time'

export function filterActivityLogsByDate(
  activityLogs: ActivityLogs,
  dateFrom: Date | undefined,
  dateTo: Date | undefined
) {
  return activityLogs.filter((log) => {
    if (dateFrom && log.timestamp < getTimestampSecond(dateFrom)) return false
    if (dateTo && log.timestamp > getTimestampSecond(dateTo)) return false
    return true
  })
}
