import { Row, Cell, Body } from '@toasttab/buffet-pui-table'
import { Table, flexRender, AccessorKeyColumnDef } from '@tanstack/react-table'

export interface TableRowsProps<T> {
  table: Table<T>
  testId?: string
}

export function TableRows<T>({ table, testId }: TableRowsProps<T>) {
  return (
    <Body>
      {table.getRowModel().rows.map((row) => (
        <Row key={row.id} testId={testId}>
          {row.getVisibleCells().map((cell) => {
            const columnDefinition = cell.column
              .columnDef as AccessorKeyColumnDef<T>
            return (
              <Cell
                key={cell.id}
                testId={`value-${String(columnDefinition.accessorKey)}`}
                className='truncate align-middle'
              >
                {flexRender(columnDefinition.cell, cell.getContext())}
              </Cell>
            )
          })}
        </Row>
      ))}
    </Body>
  )
}
