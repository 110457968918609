import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { PageBody, Page } from '@toasttab/buffet-pui-config-templates'
import { HomepageCard } from '../HomepageCard'
import { homepageResources } from '../../constants'

export interface HomePageProps {
  testId?: string
}

export const HomePage = ({ testId = 'home-page' }: HomePageProps) => {
  const { partner } = useDepotBanquetProps()

  return (
    <Page className='bg-gray-0' testId={testId}>
      <PageBody>
        <div data-testid='home-title' className='type-headline-2 py-9'>
          {partner.name}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-x-11 gap-y-6'>
          {Object.values(homepageResources).map((resource) => (
            <HomepageCard
              key={resource.title}
              description={resource.description}
              title={resource.title}
              actionTitle={resource.actionTitle}
              actionLink={resource.actionLink}
              imageComponent={resource.imageComponent}
            />
          ))}
        </div>
      </PageBody>
    </Page>
  )
}
