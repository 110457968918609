import { CopyButton } from '@local/copy-button'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { useMemo } from 'react'
import { useClientSecretContext } from '../../contexts'
import { usePermission } from '../../../../../src/utils/permissions'
import { Credentials } from '../../types'
import { GreyTextbox } from '../GreyTextbox'
import { RotateSecretContainer } from '../RotateSecretContainer'
import { Link } from 'react-router-dom'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'

export interface CredentialsSectionProps {
  testId?: string | number
  credentials: Credentials
}

export const CredentialsSection = ({
  testId = `credentials-section`,
  credentials
}: CredentialsSectionProps) => {
  const { rotationInfo, setIsRotateModalOpen } = useClientSecretContext()
  const canRotateSecret = usePermission('rotateSecret')

  const lastRotationDetails = useMemo(() => {
    const createdAt = rotationInfo?.createdAt
    const performedByEmail = rotationInfo?.performedByEmail
    if (createdAt && performedByEmail) {
      return (
        <>
          Last modified: {createdAt} by {performedByEmail}.
          <Link to='/activity-log' className='px-1 inline-link'>
            View activity log.
          </Link>
        </>
      )
    }
  }, [rotationInfo?.createdAt, rotationInfo?.performedByEmail])

  return (
    <div data-testid={testId}>
      <GreyTextbox
        label='Client name'
        helperText='Client name appears in your list of applications and acts as the short-hand for quick identification.'
        testId='client-name-textbox'
      >
        <div className='flex flex-col justify-center'>
          <p>{credentials.name}</p>
        </div>
        <div className='flex flex-col justify-center'>
          <CopyButton
            copyText={credentials.name}
            testId='client-name-copy-button'
          />
        </div>
      </GreyTextbox>

      <GreyTextbox
        label='Client ID'
        helperText='Client ID is an identifying credential used for authentication of the application.'
        testId='client-id-textbox'
      >
        <div className='flex flex-col justify-center w-2/3'>
          <p className='break-words'>{credentials.clientId}</p>
        </div>
        <div className='flex flex-col justify-center'>
          <CopyButton
            copyText={credentials.clientId}
            testId='client-id-copy-button'
          />
        </div>
      </GreyTextbox>

      <GreyTextbox
        label='Client secret'
        helperText={lastRotationDetails}
        testId='secret-textbox'
      >
        <div className='flex flex-col justify-center w-2/3'>
          <span className='type-overline font-bold pt-1 select-none'>
            ********
          </span>
        </div>
        <div className='flex flex-col justify-center mr-4'>
          {canRotateSecret ? (
            <Button
              iconLeft={<AutorenewIcon accessibility='decorative' />}
              variant={Button.Variant?.['text-link']}
              onClick={() => setIsRotateModalOpen(true)}
            >
              Rotate
            </Button>
          ) : (
            <InfoTooltip testId='rotate-secret-tooltip'>
              Contact your administrator for access to view or rotate client
              secret
            </InfoTooltip>
          )}
        </div>
      </GreyTextbox>

      <RotateSecretContainer />
    </div>
  )
}
