import { Button } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { HelperText, Label } from '@toasttab/buffet-pui-text-base'
import {
  ChangeEvent,
  createRef,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback
} from 'react'
import { ImageInputContainer } from '../ImageInputContainer/ImageInputContainer'
import { RenderImage } from '../RenderImage/RenderImage'
import { useValidateImage } from '../useValidateImage/useValidateImage'

export interface DepotImageInputProps {
  id?: string
  testId?: string
  wrapperClassNames?: string
  label?: ReactNode
  helperText?: ReactNode
  existingImageUrl: string | null
  disabled?: boolean

  imageUploading?: boolean
  setImageUploading?: Dispatch<SetStateAction<boolean>>

  uploadImage: (imageBase64: string) => Promise<void>
  removeExistingImage?: () => void

  accept?: string
  maxSize?: number
  minDimension?: number
  maxDimension?: number
}

export function DepotImageInput({
  id = 'depot-image-input',
  testId = 'depot-image-input',
  wrapperClassNames,
  label,
  helperText,
  existingImageUrl,
  disabled,

  imageUploading,
  setImageUploading,

  uploadImage,
  removeExistingImage,

  accept,
  maxSize,
  minDimension,
  maxDimension
}: DepotImageInputProps) {
  const fileInput = createRef<HTMLInputElement>()

  const validateImage = useValidateImage(
    accept,
    maxSize,
    minDimension,
    maxDimension
  )

  const onImageChange = useCallback(
    async (newFile: File) => {
      setImageUploading && setImageUploading(true)
      const validateResult = await validateImage(newFile)
      if (!validateResult) {
        setImageUploading && setImageUploading(false)
        return
      }

      try {
        await uploadImage(validateResult)
      } finally {
        setImageUploading && setImageUploading(false)
      }
    },
    [setImageUploading, uploadImage, validateImage]
  )

  const handleFileInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files[0]) {
      onImageChange(evt.target.files[0])
    }
  }

  return (
    <div>
      {label && (
        <Label htmlFor={id} data-testid={`${testId}-label`}>
          {label}
        </Label>
      )}
      <ImageInputContainer
        testId={`${testId}-container`}
        onChange={onImageChange}
        disabled={disabled || imageUploading}
        wrapperClassNames={wrapperClassNames}
      >
        <div className='flex flex-col'>
          <RenderImage
            testId={`${testId}-render-image`}
            imageUrl={existingImageUrl}
            uploading={imageUploading}
          />
          {removeExistingImage && existingImageUrl && (
            <Button
              variant='link'
              testId={`${testId}-remove-image`}
              onClick={removeExistingImage}
            >
              <span className='text-error'>Delete</span>
            </Button>
          )}
        </div>
        <Button
          onClick={() => fileInput.current?.click()}
          variant='secondary'
          disabled={disabled || imageUploading}
          testId={`${testId}-button`}
          iconLeft={
            imageUploading && (
              <AutorenewIcon
                className='animate-spin'
                accessibility='decorative'
              />
            )
          }
        >
          {imageUploading ? 'Uploading' : 'Upload a new image'}
        </Button>
        <input
          id={id}
          name={id}
          ref={fileInput}
          data-testid={`${testId}-input`}
          accept={accept}
          disabled={disabled}
          type='file'
          className='hidden'
          onChange={handleFileInputChange}
          // reset input element onClick
          // so user can select same image after failed upload
          onClick={(e) => {
            if (e.currentTarget?.value) e.currentTarget.value = ''
          }}
        />
        <HelperText helperText='or drag and drop' />
      </ImageInputContainer>
      {helperText && <HelperText helperText={helperText} />}
    </div>
  )
}
