export function getFileDisplaySize(fileByteSize: number): string {
  const kb = 1024
  const mb = kb * kb
  const gb = mb * kb

  let fileSize = ''

  if (fileByteSize < kb) {
    fileSize = `~1kb`
  } else if (fileByteSize >= kb && fileByteSize < mb) {
    fileSize = `${(fileByteSize / kb).toFixed(1)}kb`
  } else if (fileByteSize >= mb && fileByteSize < gb) {
    fileSize = `${(fileByteSize / mb).toFixed(1)}mb`
  } else {
    fileSize = `${(fileByteSize / gb).toFixed(1)}gb`
  }

  return fileSize.replace(/\.0([km]b)$/, '$1')
}
