import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useMemo } from 'react'

export function usePermission(name: string): boolean {
  const { partner, permissions } = useDepotBanquetProps()
  return useMemo(() => {
    for (const item of permissions) {
      if (item.partner === partner.guid && item.operation === name) {
        return !!item.enabled ? item.enabled : false
      }
    }
    return false
  }, [name, partner, permissions])
}
