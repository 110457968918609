import { Link } from 'react-router-dom'
import {
  ContentFooter,
  ContentHeader,
  ContinueButton,
  NavigationContainer,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { CustomPageAside } from '../CustomPageAside/CustomPageAside'
import { PageAsidePreviewIntegrationTile } from '../PageAsidePreviewIntegrationTile/PageAsidePreviewIntegrationTile'
import { Action } from '../PreviewIntegrationTile/PreviewIntegrationTile'
import { PartnerTileInfo } from '../../types/partnerInfo.type'

export function SubmittedTileStepBody({
  editPartnerTileInfo
}: {
  editPartnerTileInfo: PartnerTileInfo
}) {
  return (
    <PageBody>
      <PageMain>
        <ContentHeader
          title='Listing updated'
          description='Your changes have been saved and will be visible on the Toast Partner Integrations page within a few minutes.'
          contentDivider
        />
        <ContentFooter>
          <NavigationContainer>
            <ContinueButton
              testId='submitted-finish-button'
              as={Link}
              to='/marketplace-listing'
            >
              Finish
            </ContinueButton>
          </NavigationContainer>
        </ContentFooter>
      </PageMain>
      <CustomPageAside
        testId='listing-page-aside'
        topContent={
          <PageAsidePreviewIntegrationTile
            iconUrl={editPartnerTileInfo.iconUrl}
            name={editPartnerTileInfo.name}
            description={editPartnerTileInfo.description}
            partnerIntegrationCategories={editPartnerTileInfo.categories}
            primaryAction={editPartnerTileInfo.primaryAction as Action}
            primaryCustomCtaLabel={editPartnerTileInfo.primaryCustomCtaLabel}
            secondaryAction={editPartnerTileInfo.secondaryAction as Action}
            secondaryCustomCtaLabel={
              editPartnerTileInfo.secondaryCustomCtaLabel
            }
          />
        }
      />
    </PageBody>
  )
}
