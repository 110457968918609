import * as Yup from 'yup'

export const descriptionMaxLength = 120
const phoneNumberRegex =
  /^((\+|00)\d{1,3}\s?)?\(?\d{1,3}\)?[\s.-]?\d{3,4}[\s.-]?\d{4}$/

export const MarketplaceListingSchema = Yup.object().shape({
  description: Yup.string()
    .required('A description is required.')
    .max(
      descriptionMaxLength,
      `Description must be ${descriptionMaxLength} characters or less.`
    )
})

export const phoneNumberErrorText = 'A valid phone number is required.'
export const supportEmailErrorText = 'Invalid email.'

export const SupportSectionSchema = Yup.object().shape({
  supportPhone: Yup.string()
    .matches(phoneNumberRegex, phoneNumberErrorText)
    .nullable(),
  supportEmail: Yup.string().email(supportEmailErrorText).nullable(),
  mainUrl: Yup.string().required('A valid url is required.').url('Invalid url.')
})
