import { Button } from '@toasttab/buffet-pui-buttons'
import { DateRangePicker } from '@toasttab/buffet-pui-date-picker'
import { DefaultStaticRange } from '@toasttab/buffet-pui-date-picker/dist/types/DatePickerWithDefinedRanges'
import { DateRange, endOfDay } from '@toasttab/buffet-pui-date-utilities'
import { useCallback, useMemo, useState } from 'react'
import { ActivityLogs } from '../../types'
import { ActivityLogTable } from '../ActivityLogTable/ActivityLogTable'
import { ActivityLogNoResultFromFilter } from './ActivityLogNoResultFromFilter'
import { getDefinedRanges } from './utils/definedRange'
import { filterActivityLogsByDate } from './utils/filterActivityLogsByDate'

export function ActivityLogTableWithFilters({
  activityLogs
}: {
  activityLogs: ActivityLogs
}) {
  const [dateRange, setDateRange] = useState<DateRange | undefined>()

  const filteredActivityLog = useMemo(
    () =>
      filterActivityLogsByDate(activityLogs, dateRange?.from, dateRange?.to),
    [activityLogs, dateRange?.from, dateRange?.to]
  )

  const handleClearFilters = useCallback(() => {
    setDateRange(undefined)
  }, [])

  const definedRanges: DefaultStaticRange[] = useMemo(
    () =>
      Object.entries(getDefinedRanges()).map(([label, range]) => ({
        label,
        range
      })),
    []
  )

  return (
    <>
      <div className='flex mb-4'>
        <DateRangePicker
          numberOfMonths={2}
          value={dateRange}
          onChange={(value?: DateRange) =>
            setDateRange({
              from: value?.from,
              to: value?.to ? endOfDay(value?.to) : undefined
            })
          }
          fromDate={new Date('2023-01-01')}
          toDate={new Date()}
          showDefinedRanges
          definedRanges={definedRanges}
        />
        {dateRange && (
          <Button
            variant='link'
            aria-label='clear-selected-date-range'
            onClick={() => setDateRange(undefined)}
          >
            Clear
          </Button>
        )}
      </div>
      {filteredActivityLog.length ? (
        <ActivityLogTable activityLogs={filteredActivityLog} />
      ) : (
        <ActivityLogNoResultFromFilter
          handleClearFilters={handleClearFilters}
        />
      )}
    </>
  )
}
