import { Dispatch, SetStateAction } from 'react'
import {
  HeadingCell,
  SortIndicator,
  SortDirection as SortDirect
} from '@toasttab/buffet-pui-table'
import { ColumnInstance } from 'react-table'
import { SortDirection, SortField, TableParams } from '../../types'

export const getHeaderCells = (
  headers: ColumnInstance<object>[],
  tableParams: TableParams,
  setTableParams: Dispatch<SetStateAction<TableParams>>
) => {
  return headers.map((column: any) => {
    const {
      id,
      isSorted,
      isSortedDesc,
      className,
      getHeaderProps,
      getSortByToggleProps,
      disableSortBy,
      render
    } = column

    const headerProps = getHeaderProps({
      className: className,
      ...getSortByToggleProps()
    })

    const getSortDirection = () =>
      !isSorted
        ? SortDirect.NONE
        : isSortedDesc
        ? SortDirect.DESC
        : SortDirect.ASC

    const handleOnSortClick = (e: KeyboardEvent) => {
      if (disableSortBy) {
        e.stopPropagation()
        return false
      }

      headerProps.onClick(e)

      if (!isSorted) {
        setTableParams({
          ...tableParams,
          sortField: SortField[id as keyof typeof SortField],
          sortDirection: SortDirection.ASC
        })
      } else {
        setTableParams({
          ...tableParams,
          sortField: isSortedDesc
            ? SortField.createdDate
            : SortField[id as keyof typeof SortField],
          sortDirection: SortDirection.DESC
        })
      }
    }

    return (
      <HeadingCell
        {...headerProps}
        key={id}
        tabIndex={0}
        testId={`data-table-${id}-heading`}
        className='type-default align-center'
        isSorted={isSorted}
        {...(!disableSortBy && { role: 'button' })}
        onClick={handleOnSortClick}
      >
        <span className='flex flex-row items-center'>
          {render('Header')}
          {!disableSortBy && (
            <SortIndicator
              className='w-2 h-full'
              direction={getSortDirection()}
            />
          )}
        </span>
      </HeadingCell>
    )
  })
}
