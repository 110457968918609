import { loadImageFromBase64 } from '../loadImageFromBase64/loadImageFromBase64'
export async function validateDimensions(
  base64Data: string,
  minDimension?: number,
  maxDimension?: number
) {
  if (!minDimension && !maxDimension) return true
  let img
  try {
    img = await loadImageFromBase64(base64Data)
  } catch (e) {
    return false
  }

  // image not valid
  if (!img?.width || !img?.height) return false
  if (minDimension && (img.width < minDimension || img.height < minDimension))
    return false
  if (maxDimension && (img.width > maxDimension || img.height > maxDimension))
    return false

  return true
}
