import { Image } from '@toasttab/buffet-pui-image'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { memo } from 'react'
import cx from 'classnames'

export type RenderImageProps = {
  imageUrl: string | null
  uploading?: boolean
  testId?: string
  wrapperClasses?: string
}

export const RenderImage = memo(
  ({
    imageUrl,
    uploading,
    testId = 'render-image',
    wrapperClasses = 'h-24 w-24 rounded'
  }: RenderImageProps) => {
    if (uploading) {
      return (
        <Skeleton
          className={wrapperClasses}
          data-testid={`${testId}-loading`}
        />
      )
    }
    if (imageUrl) {
      return (
        <Image
          src={imageUrl}
          alt='partner-logo'
          containerClassName={wrapperClasses}
          testId={testId}
          // prevent drag image
          style={{ pointerEvents: 'none', width: '100%', height: 'auto' }}
        />
      )
    }
    return (
      <div
        className={cx(
          'p-1 float-left border border-solid border-gray-30 flex justify-center items-center',
          wrapperClasses
        )}
        data-testid={`${testId}-placeholder`}
      >
        <span>No image</span>
      </div>
    )
  }
)
