import { ReactNode } from 'react'
import { Border, HelperText, Label } from '@toasttab/buffet-pui-text-base'

export interface GreyTextboxProps {
  label: string
  helperText?: ReactNode
  testId?: string | number
  children: ReactNode
}

export const GreyTextbox = ({
  label,
  helperText = '',
  testId = `grey-textbox`,
  children
}: GreyTextboxProps) => (
  <div className='my-3' data-testid={testId}>
    <Label data-testid={`${testId}-label`}>{label}</Label>
    <Border className='h-12 bg-gray-0' readOnly>
      <div className='w-full h-full flex flex-row justify-between p-3'>
        {children}
      </div>
    </Border>
    <HelperText testId={`${testId}-helper-text`} helperText={helperText} />
  </div>
)
